<template>
  <Main center>
    <div class="terms">
      <div class="terms__payment">
        <div v-if="error" class="terms__error">
          <Toast error>{{ error }}</Toast>
        </div>

        <H h2>{{ getLocal(language, 'paymentDetails.header') }}</H>

        <P body2 class="terms__info"
          >Please enter a debit or credit card to use for your initial payment of {{ initialPayment }}. If you don’t
          start a lease within the next 7 days, we will refund this payment.</P
        >

        <div class="terms__card">
          <Grid>
            <GridItem cols="12" md="6">
              <div ref="cardName"></div>
              <Input
                :label="getLocal(language, 'paymentDetails.cardName')"
                placeholder="John Doe"
                v-model:value="cardName"
              />
            </GridItem>

            <GridItem cols="12" md="6">
              <Input :label="getLocal(language, 'paymentDetails.cardNumber')" ref="cardNumber" proxy />
            </GridItem>

            <GridItem cols="6" md="4">
              <Input
                ref="zipCodeInput"
                :label="getLocal(language, 'paymentDetails.zipCode')"
                placeholder="11000"
                mask="#####"
                v-model:value="zipCode"
                inputmode="numeric"
              />
            </GridItem>

            <GridItem cols="6" md="4">
              <Input ref="cardExpiry" proxy :label="getLocal(language, 'paymentDetails.cardExpiry')" />
            </GridItem>

            <GridItem cols="6" md="4">
              <Input ref="cardCvc" proxy label="CVC" />
            </GridItem>
          </Grid>
        </div>
      </div>
    </div>

    <template v-slot:footer>
      <div class="terms__footer">
        <div class="terms__back">
          <Button v-if="prevRoute" :to="prevRoute" link icon>
            <template v-slot:icon>
              <svg width="7" height="11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6 1L1.75 5.25 6 9.5"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>

            <span>{{ getLocal(language, 'back') }}</span>
          </Button>
        </div>

        <div class="terms__cta">
          <Button ref="submit" large expanded :loading="loading" :disabled="loading || !ready" @click="sign"
            >Make Payment</Button
          >
        </div>
      </div>
    </template>
  </Main>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { getLocal } from '../data/locales'

import { loadStripe } from '@stripe/stripe-js'

import { moneyMask } from '../helpers/general'

import Button from '../components/common/Button.vue'
import Input from '../components/common/Input.vue'
import Grid from '../components/common/Grid.vue'
import GridItem from '../components/common/GridItem.vue'
import H from '../components/common/H.vue'
import P from '../components/common/P.vue'
import Main from '../components/Main'
import Toast from '../components/common/Toast.vue'

const stripeInputOptions = {
  classes: {
    base: 'input__stripe'
  },
  style: {
    base: {
      fontFamily: `'Inter', sans-serif`,
      lineHeight: `58px`,
      fontWeight: `500`,
      fontSize: `16px`,
      color: `#323b4b`,
      backgroundColor: `rgba(0, 0, 0, 0)`,
      fontSmoothing: `antialiased`,

      '::placeholder': {
        fontWeight: 500,
        fontSize: `16px`,
        color: `#b0b7c3`,
        fontFamily: `'Inter', sans-serif`
      }
    }
  }
}

export default {
  components: {
    Button,
    Input,
    Grid,
    GridItem,
    H,
    P,
    Main,
    Toast
  },
  setup() {
    const store = useStore()

    return {
      store,
      zipCodeInput: ref(false),
      submit: ref(null)
    }
  },
  data() {
    return {
      loading: false,
      error: '',
      stripe: null,
      cardName: '',
      zipCode: '',
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      cardNumberReady: false,
      cardExpiryReady: false,
      cardCvcReady: false
    }
  },
  watch: {
    zipCode(value) {
      if (value.length === 5) {
        this.cardExpiry.focus()
      }
    },
    cardNumberReady(value) {
      if (value === true) {
        this.zipCodeInput.setFocus()
      }
    },
    cardExpiryReady(value) {
      if (value === true) {
        this.cardCvc.focus()
      }
    },
    cardCvcReady(value) {
      if (value === true) {
        this.submit.setFocus()
      }
    }
  },
  methods: {
    getLocal,
    async sign() {
      this.loading = true
      this.error = ''

      this.store.commit('application/setCardAuthToken', '')

      const { token, error } = await this.stripe.createToken(this.cardNumber, {
        name: this.cardName,
        address_zip: this.zipCode
      })

      if (error) {
        this.error = `Stripe error: ${error}`
        this.loading = false
        return
      }

      const { success, message } = await this.store.dispatch('contract/signContract', {
        tokenId: token.id
      })

      const ccCard = await this.store.dispatch('application/createCard')

      if (success && ccCard.success) {
        this.store.dispatch('trackEvent', {
          eventName: 'Submit Initial Payment Direct',
          payload: {
            source: this.store.state.application.source,
            last4_card: token.card.last4,
            name_on_card: token.card.name,
            zipcode: token.card.address_zip,
            expiry: `${token.card.exp_month}/${token.card.exp_year}`,
            initial_payment_amount: this.initialPayment
          }
        })
        await this.store.dispatch('redirectOnStatus')
      } else {
        this.error = message || ccCard.message
        this.loading = false
      }
    }
  },
  async mounted() {
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY)

    const elements = this.stripe.elements()

    this.cardNumber = elements.create('cardNumber', stripeInputOptions)
    this.cardExpiry = elements.create('cardExpiry', stripeInputOptions)
    this.cardCvc = elements.create('cardCvc', stripeInputOptions)

    this.cardNumber.mount(this.$refs.cardNumber.$refs.proxy)
    this.cardExpiry.mount(this.$refs.cardExpiry.$refs.proxy)
    this.cardCvc.mount(this.$refs.cardCvc.$refs.proxy)

    this.cardNumber.on('change', (e) => (this.cardNumberReady = e.complete))
    this.cardExpiry.on('change', (e) => (this.cardExpiryReady = e.complete))
    this.cardCvc.on('change', (e) => (this.cardCvcReady = e.complete))
  },
  computed: {
    initialPayment() {
      return moneyMask(this.store.getters['pricing/getInitialPayment'])
    },
    prevRoute() {
      return this.$route.meta.prev
    },
    ready() {
      if (!(this.cardNumberReady && this.cardExpiryReady && this.cardCvcReady)) {
        return false
      }

      if (this.cardName.length < 2) {
        return false
      }

      if (!(this.zipCode.length === 5)) {
        return false
      }

      return true
    },
    language() {
      return this.$store.getters.getLanguage
    }
  }
}
</script>

<style lang="scss" scoped>
.terms {
  &__error {
    margin-bottom: 24px;
  }

  &__info {
    margin-top: 16px;
  }

  &__payment {
    margin-top: 32px;

    @include media-query(sm) {
      margin-top: 72px;
    }
  }

  &__card {
    margin-top: 32px;

    @include media-query(sm) {
      margin-top: 48px;
    }
  }

  &__footer {
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__back {
    flex: 1 0 auto;
    margin-right: 32px;
  }

  &__cta {
    flex: 0 1 360px;
  }
}
</style>
